<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">New Assessment Schedule</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'schedule-ojt-assessment-create',
                          'schedule-ojt-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedProgram"
                        :disabled="currentRouteName == 'ojt-assessment-update'"
                        v-model="selectedProgram"
                        label="name"
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['schedule-ojt-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedProgram"
                        label="name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="level" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'schedule-ojt-assessment-create',
                          'schedule-ojt-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedLevel"
                        v-model="schedule.level"
                        :disabled="currentRouteName == 'ojt-assessment-update'"
                        label="name"
                        :options="levels"
                        :selectable="(options) => isSelectableLevel(options)"
                        :reduce="(name) => name.name"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.schedule.level.$error || errorFor('level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.schedule.level.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['schedule-ojt-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="schedule.level"
                        label="name"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'schedule-ojt-assessment-create',
                          'schedule-ojt-assessment-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :options="batch"
                        :disabled="currentRouteName == 'ojt-assessment-update'"
                        :clearable="false"
                        :selectable="(options) => isSelectable(options)"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="
                        hasPermissions(['schedule-ojt-assessment-list'])
                      "
                    >
                      <v-select
                        v-model="selectedBatch"
                        label="batch_no"
                        :clearable="false"
                        disabled
                      >
                      </v-select>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-2">
                    <label for="date" class="col-md-5 form-label">
                      From Date
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-6"
                      v-if="
                        hasPermissions([
                          'schedule-ojt-assessment-create',
                          'schedule-ojt-assessment-edit',
                        ])
                      "
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="schedule.from_date"
                        disabled
                        v-if="currentRouteName == 'ojt-assessment-update'"
                      />
                      <flat-pickr
                        v-model="schedule.from_date"
                        class="form-control"
                        @on-change="
                          changeFromDate(schedule.from_date, schedule.to_date)
                        "
                        :disabled="currentRouteName == 'ojt-assessment-update'"
                        placeholder="Select From Date"
                        name="date"
                        :config="flatPickrDateConfig"
                        v-else
                      >
                      </flat-pickr>
                      <v-errors
                        :serverErrors="errorFor('date')"
                        :vuelidateErrors="{
                          errors: v$.schedule.from_date.$errors,
                          value: 'From Date',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-6"
                      v-else-if="
                        hasPermissions(['schedule-ojt-assessment-list'])
                      "
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="schedule.from_date"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label for="date" class="col-md-5 form-label">
                      To Date
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-6"
                      v-if="
                        hasPermissions([
                          'schedule-ojt-assessment-create',
                          'schedule-ojt-assessment-edit',
                        ])
                      "
                    >
                      <flat-pickr
                        v-model="schedule.to_date"
                        class="form-control"
                        @on-change="
                          changeToDate(schedule.from_date, schedule.to_date)
                        "
                        placeholder="Select To Date"
                        name="date"
                        :config="flatPickrDateConfig"
                      >
                      </flat-pickr>
                      <v-errors
                        :serverErrors="errorFor('date')"
                        :vuelidateErrors="{
                          errors: v$.schedule.to_date.$errors,
                          value: 'To Date',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-6"
                      v-else-if="
                        hasPermissions(['schedule-ojt-assessment-list'])
                      "
                    >
                      <flat-pickr
                        v-model="schedule.to_date"
                        class="form-control"
                        placeholder="Select To Date"
                        name="date"
                        :config="flatPickrDateConfig"
                        disabled
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="float-end">
                                <button v-show="!loading" type="button" @click="addSchedule()"
                                    class="btn btn-sm btn-blue waves-effect waves-light mb-2">
                                    <i class="mdi mdi-plus-circle"></i> Add
                                </button>
                            </div> -->

              <div
                class="progress mt-5"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div
                class="row mt-2 text-center"
                v-if="!loading"
                style="overflow-x: scroll"
              >
                <div class="d-flex">
                  <div class="col-md-2 text-start ms-1">
                    <label for="name" class="form-label"> Employee Name </label>
                  </div>
                  <div class="col-md-1 me-3">
                    <label for="name" class="form-label"> Employee_Id </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label">
                      Date
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label">
                      Time
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-2 me-2">
                    <label for="name" class="form-label">
                      Assessor
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label">
                      Assessment Location
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-2">
                    <label for="name" class="form-label">
                      Remark
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </div>

                <div
                  class="row mb-4"
                  v-if="
                    employeeLists &&
                    hasPermissions([
                      'schedule-ojt-assessment-create',
                      'schedule-ojt-assessment-edit',
                    ])
                  "
                >
                  <div
                    v-for="(item, index) in schedule.assessment_details"
                    :key="index"
                    class="d-flex m-2"
                  >
                    <div class="col-md-2 me-1">
                      <span>{{ item.employee_name }}</span>
                    </div>
                    <div class="col-md-1 me-1">
                      <span>{{ item.employee_id }}</span>
                    </div>
                    <div class="col-md-2 me-1">
                      <flat-pickr
                        v-model="item.date"
                        class="form-control"
                        placeholder="Select Date"
                        name="date"
                        :config="flatPickrDateConfigLimit"
                      ></flat-pickr>
                    </div>
                    <div class="col-md-2 me-1">
                      <flat-pickr
                        v-model="item.time"
                        class="form-control"
                        placeholder="Select Time"
                        name="time"
                        :config="flatPickrTimeConfig"
                      ></flat-pickr>
                    </div>
                    <div class="col-md-2 me-1">
                      <v-select
                        v-model="item.assessor_employee_id"
                        label="name"
                        :options="assessor"
                        :selectable="
                          (options) => isSelectableAssessor(options, index)
                        "
                        :reduce="(name) => name.id"
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                    <div class="col-md-2 me-1">
                      <input
                        type="text"
                        id="location"
                        name="location"
                        class="form-control"
                        placeholder="Assessment Location"
                        v-model="item.assessment_location"
                      />
                    </div>
                    <div class="col-md-2 me-1">
                      <input
                        type="text"
                        id="remark"
                        name="remark"
                        class="form-control"
                        placeholder="Remark"
                        v-model="item.remark"
                      />
                    </div>
                    <div class="col-md-1" v-show="item.status != 1">
                      <div v-show="!loading" @click="removeSchedule(index)">
                        <i class="fas fa-trash-alt mx-1"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row mb-4"
                  v-else-if="hasPermissions(['schedule-ojt-assessment-list'])"
                >
                  <div
                    v-for="(item, index) in schedule.assessment_details"
                    :key="index"
                    class="d-flex m-2"
                  >
                    <div class="col-md-2 me-1">
                      <span>{{ item.employee_name }}</span>
                    </div>
                    <div class="col-md-1 me-1">
                      <span>{{ item.employee_id }}</span>
                    </div>
                    <div class="col-md-2 me-1">
                      <flat-pickr
                        v-model="item.date"
                        class="form-control"
                        placeholder="Select Date"
                        name="date"
                        :config="flatPickrDateConfigLimit"
                        disabled
                      ></flat-pickr>
                    </div>
                    <div class="col-md-2 me-1">
                      <flat-pickr
                        v-model="item.time"
                        class="form-control"
                        placeholder="Select Time"
                        name="time"
                        :config="flatPickrTimeConfig"
                        disabled
                      ></flat-pickr>
                    </div>
                    <div class="col-md-2 me-1">
                      <v-select
                        v-model="item.assessor_employee_id"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                    <div class="col-md-2 me-1">
                      <input
                        type="text"
                        id="location"
                        name="location"
                        class="form-control"
                        placeholder="Assessment Location"
                        v-model="item.assessment_location"
                        disabled
                      />
                    </div>
                    <div class="col-md-2 me-1">
                      <input
                        type="text"
                        id="remark"
                        name="remark"
                        class="form-control"
                        placeholder="Remark"
                        v-model="item.remark"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- end row -->
              <div
                class="row"
                v-if="
                  hasPermissions([
                    'schedule-ojt-assessment-create',
                    'schedule-ojt-assessment-edit',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'ojt-assessment' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="
                        schedule.assessment_details == '' ||
                        schedule.assessment_details == []
                      "
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="Data is nothing to save!"
                    >
                      Save
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'ojt-assessment-update'
                          ? updateSchedule()
                          : createSchedule()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
    flatPickr,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: [],
      batch: [],
      levels: [],
      employeeLists: [],
      assessor: [],
      flatPickrDateConfig: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },
      flatPickrDateConfigLimit: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },
      flatPickrDateConfigLimitTo: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },
      // flatPickrDateConfigLimitUpdate: {
      //   minDate: null,
      //   defaultDate: null
      // },
      flatPickrTimeConfig: {
        enableTime: true,
        noCalendar: true,
        defaultTime: "08:00:00",
        dateFormat: "H:i:s",
        altInput: true,
        altFormat: "H:i",
        minTime: "08:00",
        maxTime: "17:00",
      },
      schedule: {
        type: "OJT",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        level: "",
        available: "",
        from_date: "",
        to_date: "",
        assessment_details: [
          {
            employee_name: "",
            employee_id: "",
            date: "",
            time: "",
            assessor_employee_id: "",
            assessor_employee_name: "",
            remark: "",
            assessment_location: "",
          },
        ],
      },
      usedBatch: [],
      oldScheduleDatas: [],
      selectedProgram: null,
      selectedBatch: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      schedule: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        level: { required },
        available: { required },
        from_date: { required },
        to_date: { required },
      },
    };
  },
  methods: {
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableLevel(options) {
      if (!this.schedule.level || !options) return true;
      return this.schedule.level != options.name;
    },
    isSelectableAssessor(options, index) {
      // console.
      if (
        !this.schedule.assessment_details[index].assessor_employee_id ||
        !options
      )
        return true;
      return (
        this.schedule.assessment_details[index].assessor_employee_id !=
        options.id
      );
    },
    isSelectable(options) {
      if (this.selectedBatch) {
        // Check if the program with the same id exists in usedBatch
        const batchExists = this.usedBatch.some(
          (item) => item.batch_id == this.selectedBatch.id
        );
        // If the batch doesn't exist, add it to usedBatch
        if (!batchExists) {
          this.usedBatch = this.usedBatch.filter((item) => !item.is_selected);
          this.usedBatch.push({
            program_id: this.selectedBatch.program.id,
            batch_id: this.selectedBatch.id,
            level: this.schedule.level,
            is_selected: true,
          });
        }
      }
      // Now, check if the options.id exists in usedBatch
      return !this.usedBatch.some(
        (item) =>
          item.program_id === this.selectedProgram.id &&
          item.level == this.schedule.level &&
          item.batch_id == options.id
      );
    },
    // addSchedule() {
    //     this.schedule.assessment_details.push({
    //         employee_name: "",
    //         employee_id: "",
    //         date: "",
    //         time: "",
    //         assessor_employee_id: "",
    //         remark: ""
    //     })
    // },
    removeSchedule(index) {
      this.schedule.assessment_details.splice(index, 1);
    },
    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.batch = [];
        this.employeeLists = [];
        this.schedule.assessment_details = [];
        this.schedule.level = "";
        this.schedule.program_id = this.selectedProgram.id;
        this.schedule.program_name = this.selectedProgram.name;
        this.schedule.program_short_name = this.selectedProgram.short_name;
        this.getAllLevels();
      }
    },
    getSelectedLevel() {
      this.selectedBatch = null;
      this.batch = [];
      this.employeeLists = [];
      this.schedule.assessment_details = [];
      this.getBatchByProgram(this.schedule.program_id);
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.employeeLists = [];
        this.schedule.assessment_details = [];
        // this.schedule.level = "";
        this.schedule.program_batch_id = this.selectedBatch.id;
        this.schedule.program_batch_no = this.selectedBatch.batch_no;
        this.schedule.join_date = this.selectedBatch.join_date;
        this.schedule.available = this.selectedBatch.available;
        this.getEmployeeByBatch(this.schedule.program_batch_id);
      }
    },
    async getPrograms() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getBatchByProgram(program_id) {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batches/list/?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batch/employees/list/?batch_id=${batch_id}`
        )
        .then((response) => {
          this.employeeLists = response.data.data[0];

          this.employeeLists.employee_ids.forEach((item) => {
            // Check if employee_id already exists in assessment_details
            const employeeExists = this.schedule.assessment_details.some(
              (element) => element.employee_id === item.employee_ID
            );
            // If not present, add the employee details
            if (!employeeExists) {
              this.schedule.assessment_details.push({
                // id: 0, //cuz we need to send id to backend to check existed emp
                employee_name: item.name,
                employee_id: item.employee_ID,
                date: "",
                time: "",
                assessor_employee_id: "",
                assessor_employee_name: "",
                remark: "",
                assessment_location: "",
              });
            }
            // console.log(this.schedule.assessment_details)
          });

          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },

    async getAllLevels() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },

    changeFromDate(from_date, to_date) {
      this.schedule.from_date = from_date;
      this.schedule.to_date = to_date;

      if (this.schedule.to_date) {
        if (this.schedule.from_date > this.schedule.to_date) {
          this.toast.error("To Date Is Smaller Than From Date!");
        }
      }

      this.flatPickrDateConfigLimit.minDate = this.schedule.from_date;
      this.flatPickrDateConfigLimit.maxDate = this.schedule.to_date;
    },

    changeToDate(from_date, to_date) {
      this.schedule.from_date = from_date;
      this.schedule.to_date = to_date;

      if (this.schedule.from_date > this.schedule.to_date) {
        this.toast.error("To Date Is Smaller Than From Date!");
      }

      this.flatPickrDateConfigLimit.minDate = this.schedule.from_date;
      this.flatPickrDateConfigLimit.maxDate = this.schedule.to_date;
    },

    async getHRDemployee() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/hrd/members`)
        .then((response) => {
          this.assessor = response.data.data.map((item) => {
            return {
              id: item.emp_id,
              name: item.name,
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found HRD Assessor Employee!");
        });
    },

    async getAllocationById() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/assessment/${this.$route.params.id}`)
        .then((response) => {
          const result = response.data.data;
          // this.flatPickrDateConfig.maxDate = result.to_date;

          // this.flatPickrDateConfig.disable = [
          //     function(date) {
          //     return date <= new Date(result.to_date);
          //     },
          // ];
          this.schedule = result;
          // console.log(this.schedule);
          this.selectedProgram = {
            id: result.program_id,
            name: result.program_name,
            short_name: result.program_short_name,
          };
          this.selectedBatch = {
            id: result.program_batch_id,
            batch_no: result.program_batch_no,
            join_date: result.join_date,
            available: result.available,
          };
          this.schedule.assessment_details = result.assessment_details.map(
            (item) => {
              return {
                // id: item.id,
                employee_name: item.employee_name,
                employee_id: item.employee_id,
                date: item.date,
                time: item.time,
                assessor_employee_id: item.assessor_employee_id,
                assessor_employee_name: item.assessor_employee_name,
                remark: item.remark,
                assessment_location: item.assessment_location,
              };
            }
          );

          this.flatPickrDateConfigLimit.minDate = result.from_date;
          this.flatPickrDateConfigLimit.maxDate = result.to_date;

          this.flatPickrDateConfig.minDate = result.to_date;

          // this.getBatchByProgram(this.selectedProgram.id);
          this.getEmployeeByBatch(this.selectedBatch.id);
        })
        .catch(() => {
          this.toast.error("Not Found Data!");
        });
      this.loading = false;
    },

    checkEmptyValue(array) {
      delete array.remark;
      let scheduleData = Object.values(array);
      if (scheduleData.includes(null) || scheduleData.includes("")) {
        return false;
      } else {
        return true;
      }
    },

    addAssessorName() {
      for (const eachAssessor of this.schedule.assessment_details) {
        const assessorId = eachAssessor.assessor_employee_id;
        const matchingAssessor = this.assessor.find(
          (assessor) => assessor.id == assessorId
        );
        if (matchingAssessor) {
          eachAssessor.assessor_employee_name = matchingAssessor.name;
        }
      }
    },

    async createSchedule() {
      this.addAssessorName();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      if (this.schedule.from_date > this.schedule.to_date) {
        this.toast.error("To Date Is Smaller Than From Date!");
        return;
      }

      let checkSchedule = [];
      this.schedule.assessment_details.forEach((array) => {
        let remarkValue = array.remark;
        let schedule_data = this.checkEmptyValue(array);
        checkSchedule.push(schedule_data);
        array.remark = remarkValue;
      });
      if (checkSchedule.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      }
      this.isLoading = true;

      await axios
        .post(`${this.baseUrl}admin/v2/assessment`, this.schedule)
        .then(() => {
          this.$router.push({ name: "ojt-assessment" });
          this.toast.success("Successfully Created Schedule!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async getOldScheduleDatas() {
      this.schedule.assessment_details = this.oldScheduleDatas;
    },

    async updateSchedule() {
      this.addAssessorName();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      if (this.schedule.from_date > this.schedule.to_date) {
        this.toast.error("To Date Is Smaller Than From Date!");
        return;
      }

      let checkSchedule = [];
      this.schedule.assessment_details.forEach((array) => {
        let remarkValue = array.remark;
        let schedule_data = this.checkEmptyValue(array);
        checkSchedule.push(schedule_data);
        array.remark = remarkValue;
      });
      if (checkSchedule.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      }

      let data = {
        type: "OJT",
        program_id: this.schedule.program_id,
        program_name: this.schedule.program_name,
        program_short_name: this.schedule.program_short_name,
        program_batch_id: this.schedule.program_batch_id,
        program_batch_no: this.schedule.program_batch_no,
        join_date: this.schedule.join_date,
        available: this.schedule.available,
        level: this.schedule.level,
        from_date: this.schedule.from_date,
        to_date: this.schedule.to_date,
        assessment_details: this.schedule.assessment_details,
      };
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v2/assessment/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.$router.push({ name: "ojt-assessment" });
          this.toast.success("Successfully Schedule Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async getUsedBatch() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/assessment?type=OJT`)
        .then((response) => {
          response.data.data.forEach((item) => {
            this.usedBatch.push({
              program_id: item.program_id,
              batch_id: item.program_batch_id,
              level: item.level,
            });
          });

          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },

    // isSelectable(options) {
    //   // Check if the options' ID is present in the usedBatch array
    //   return (
    //     this.usedBatch.find((batch) => batch.program_id === this.schedule.program_id &&
    //         batch.batch_id == options.id
    //         &&
    //         batch.level == this.schedule.level
    //     ) === undefined
    //   );
    // },
  },
  created() {
    this.getUsedBatch();
    this.getPrograms();
    this.getHRDemployee();
    this.getOldScheduleDatas();
    if (this.currentRouteName == "ojt-assessment-update") {
      this.getAllocationById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
